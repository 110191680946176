import { collection, doc, getDocs, query, setDoc, where } from '@firebase/firestore';
import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Box, Tab, Tabs, TextField, Typography } from '@mui/material';
import format from 'date-fns/format';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';
import { Document, useCollection } from 'swr-firestore-v9';
import { ArchivedStoreLog, ArchivedStoreLogs, Params, StoreLog, UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { useAdmin } from '../../contexts/admin.context';
import { db } from '../../firebase';
import { getDateCode, getDateFromDateCode, incrementDateCode } from '../../utils';
import ConsumablesByDay from './ConsumablesByDay';
import ConsumablesByStores from './ConsumablesByStores';

type TabName = 'table1' | 'table2' | 'table3'

interface Props {
  userCredentials: UserCredentials
}

function ConsumablesReport(props: Props) {
  const { companySlug } = useParams<Params>()
  const [dates, setDates] = useState<DateRange<Date>>([new Date(), new Date()])
  const [dateCodes, setDateCodes] = useState<number[]>([]);
  const [tab, setTab] = useState<TabName>('table1');
  const [updating, setUpdating] = useState(false);
  const { consumables, isValidating } = useAdmin();

  const archivedStoresLogs = useCollection<ArchivedStoreLogs>(
    dateCodes.length ? `company/${companySlug}/archivedStoresLogs` : null, {
      where: [
        ['dateCode', '>=', dateCodes[0]],
        ['dateCode', '<=', dateCodes[dateCodes.length - 1]],
      ],
    }
  )

  const storesLogs = useMemo(() => {
    return (archivedStoresLogs.data || []).map(asl => asl.logs).flat()
  }, [archivedStoresLogs.data])

  const filteredConsumables = useMemo(() => {
    return consumables.filter(c => !c.disableSpend)
  }, [consumables])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabName) => {
    setTab(newValue);
  };

  useEffect(() => {
    const initEndDate = new Date()
    initEndDate.setHours(23)
    initEndDate.setMinutes(59)
    initEndDate.setDate(0)
    const initStartDate = new Date()
    initStartDate.setHours(0)
    initStartDate.setMinutes(0)
    initStartDate.setDate(1)
    initStartDate.setMonth(initStartDate.getMonth() - 1)
    setDates([initStartDate, initEndDate])
  }, [])

  useEffect(() => {
    const startDateCode = getDateCode(dates[0])
    const endDateCode = getDateCode(dates[1])
    const newDateCodes: number[] = []
    for (let dc = startDateCode; dc <= endDateCode; dc = incrementDateCode(dc)) {
      newDateCodes.push(dc)
    }
    setDateCodes(newDateCodes)
  }, [dates])

  useEffect(() => {
    if (archivedStoresLogs.data) {
      const toDayDateCode = getDateCode(new Date())
      const needUpdate = dateCodes.filter(dc => dc !== 0 && toDayDateCode !== dc && !archivedStoresLogs.data?.some(asl => asl.dateCode === dc))

      if (needUpdate.length) {
        updateAllDays(needUpdate, companySlug, setUpdating, archivedStoresLogs.mutate)
      }
    }
  }, [archivedStoresLogs.data])

  return (
    <Fragment>
      <Box sx={{ mx: 'auto', pt: 2, pb: 3, px: {md: 4, xs: 1} }}>
        <Loader open={isValidating || archivedStoresLogs.isValidating || updating} />

      <Box sx={{display: 'flex', justifyContent: 'center', my: 1, mx: 'auto'}}>
        <Box sx={{maxWidth: '240px'}}>
          <MobileDateRangePicker
            cancelText={null}
            showToolbar={false}
            startText="Период с"
            endText="Период до"
            value={dates}
            disableMaskedInput
            disableFuture
            onAccept={(newValue) => {
              setDates(newValue);
            }}
            onChange={() => {}}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} sx={{ marginRight: 1 }} size='small' /> -
                <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
              </React.Fragment>
            )}
          />
        </Box>
      </Box>

        <Tabs value={tab} onChange={handleChangeTab} centered sx={{mb: 3}} allowScrollButtonsMobile>
          <Tab value='table1' label="Общий отчет" sx={{flexShrink: 1}}/>
          <Tab value="table2" label="Отчет по складам" sx={{flexShrink: 1}}/>
        </Tabs>

        {tab === 'table1' && !!storesLogs.length &&
          <ConsumablesByDay
            dateCodes={dateCodes}
            storesLogs={storesLogs}
            consumables={filteredConsumables}
          />
        }

        {tab === 'table2' && !!storesLogs.length &&
          <ConsumablesByStores
            storesLogs={storesLogs}
            consumables={filteredConsumables}
          />
        }

        {!storesLogs.length &&
          <Typography variant='h6' sx={{ m: 5, textAlign: 'center' }}>
           За этот период не найдено событий расхода
          </Typography>
        }
      </Box>
    </Fragment>
  )
}

export default ConsumablesReport;

const updateAllDays = async (
  needUpdate: number[],
  companySlug: string,
  setUpdating: (value: React.SetStateAction<boolean>) => void,
  revalidate: KeyedMutator<Document<ArchivedStoreLogs>[] | null>
) => {
  setUpdating(true)
  const promises = needUpdate.map(dc => {
    return updateData(dc, companySlug)
  })
  await Promise.all(promises)
  await revalidate()
  setUpdating(false)
}

const updateData = async (
  dc: number,
  companySlug: string
) => {
  const q = query(
    collection(db, "company", companySlug, "storesLogs"),
    where('date', '==', format(getDateFromDateCode(dc), 'dd.MM.yyyy')),
  )

  const archivedStoresLogs: ArchivedStoreLog[] = []
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const log = doc.data() as StoreLog
    archivedStoresLogs.push({...log, id: doc.id})
  });

  await setDoc(doc(db, "company", companySlug, "archivedStoresLogs", dc.toString()), {
    logs: archivedStoresLogs,
    dateCode: dc
  })
}
