import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore';
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { Fuego } from 'swr-firestore-v9';

export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyA-fooYNmgT93bJ2CHfSL772V3eDCaxfgI",
  authDomain: "gloryline-v2.firebaseapp.com",
  projectId: "gloryline-v2",
  storageBucket: "gloryline-v2.appspot.com",
  messagingSenderId: "374632475620",
  appId: "1:374632475620:web:28ba9f7c3cf7a8de84f5f1"
}

export const fuego = new Fuego(firebaseConfig)

try {
  initializeApp(firebaseConfig)
} catch (err: any) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

export const db = getFirestore();

export const auth = getAuth();
auth.useDeviceLanguage();

export const storage = getStorage();
export const messaging = getMessaging();

enableIndexedDbPersistence(db)
  .catch((err) => {
      if (err.code === 'failed-precondition') {
          console.log("failed-precondition")
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          console.log("unimplemented")
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });

