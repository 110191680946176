import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Collapse, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { Document } from 'swr-firestore-v9';
import { TechMap, UserCredentials } from '../../apiTypes';
import { useAdmin } from '../../contexts/admin.context';
import EditTechMap from './EditTechMap';
import { getTime } from './utils';

interface Props {
  techMap: Document<TechMap>
  userCredentials: UserCredentials
}

const TechMapCard = (props: Props) => {
  const {userCredentials, techMap} = props
  const { name, object, startTime, rate, traineeRate, stores, writeOffZones, actions, archived } = techMap
  const { stores: allStores } = useAdmin()
  const storesString = stores.map(id => allStores.find(s => s.id === id)?.name || 'Неизвестный').join(', ')

  const [expanded, setExpanded] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box marginTop={2}>
      <Card variant="outlined">
        <CardHeader
          sx={{ backgroundColor: t => t.palette.grey[50], overflow: 'auto' }}
          title={object + ': ' + name + (archived ? ' (В архиве)' : '')}
          subheader={stores.length ? `Cклад: ${storesString}` : undefined}
          action={
            <Box paddingTop={1}>
              {userCredentials.techMaps_edit &&
                <IconButton onClick={() => setEditModalIsOpen(true)}>
                  <Edit/>
                </IconButton>
              }
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show details"
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          }
        />


        {userCredentials.workers_info &&
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Divider/>
            <CardContent>
              {!!writeOffZones.length &&
                <Box padding={1} >
                  <span>Зоны списания расходников:</span> &nbsp;{writeOffZones.join(', ')}
                </Box>
              }
              <Box padding={1}>
                <span>Ставка:</span> &nbsp;{rate}&nbsp;грн
              </Box>
              <Box padding={1} marginBottom={3}>
                <span>Ставка при стажировке:</span> &nbsp;{traineeRate}&nbsp;грн
              </Box>


              {!!actions.length &&
                <TableContainer>
                  <Table>
                    <TableHead style={{fontWeight: 600}}>
                      <TableRow>
                        <TableCell>Время</TableCell>
                        <TableCell>Место</TableCell>
                        <TableCell>Комплекс работ</TableCell>
                        <TableCell>Инструкция</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {actions.map((action, index) => {
                        const [start, end] = getTime(actions, index, startTime)
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              <Box width={38}>
                                <Typography variant='body2' color='textSecondary'>{start}</Typography>
                                  <Box paddingLeft={1} paddingRight={1}><Divider/></Box>
                                <Typography variant='body2' color='textSecondary'>{end}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell>{action.place}</TableCell>
                            <TableCell>{action.name}</TableCell>
                            <TableCell>{action.instructions}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </CardContent>
          </Collapse>
        }
      </Card>

      {editModalIsOpen &&
        <EditTechMap
          techMap={techMap}
          open={editModalIsOpen}
          onClose={() => setEditModalIsOpen(false)}
          userCredentials={userCredentials}
        />
      }
    </Box>
  )
}


export default TechMapCard;
