import { doc, updateDoc } from '@firebase/firestore';
import React, { Fragment, useEffect } from 'react';
import { useMessagingToken } from 'react-firehooks';
import { useAuthState } from 'react-firehooks/auth';
import { useParams } from 'react-router';
import { useCollection, useDocument } from 'swr-firestore-v9';
import { Company, Params, Worker } from '../apiTypes';
import NotAvailable from '../components/NotAvailable';
import { vapidKey } from '../constants';
import { auth, db, messaging } from '../firebase';
import MainScreen from './MainScreen';
import TerminalAuth from './TerminalAuth';

declare global {
  interface Window {
    recaptchaVerifier: any
    confirmationResult: any

  }
}

const TerminalApp = () => {
  const [userAuth, userIsValidating] = useAuthState(auth);
  const phoneNumber = userAuth?.phoneNumber
  const { companySlug } = useParams<Params>();
  const company = useDocument<Company>(userAuth ? `company/${companySlug}` : null)
  const companyName = company.data?.name;
  const [token] = useMessagingToken(messaging,  {getTokenOptions: {vapidKey: vapidKey}});

  const workers = useCollection<Worker>(phoneNumber && companyName ? `company/${companySlug}/workers` : null, {
    listen: true,
    where: [
      ['phone', '==', phoneNumber],
    ],
  })

  const worker = workers.data && workers.data[0]

  useEffect(() => {
    if (worker && worker.id && token && worker.msgToken !== token) {
      updateDoc(doc(db, "company", companySlug, "workers", worker.id), { msgToken: token});
    }
  }, [worker, companySlug, token])


  return (
    <Fragment>
      {!userIsValidating && !phoneNumber &&
        <TerminalAuth />
      }

      {!userIsValidating && phoneNumber && !workers.isValidating && !worker &&
        <NotAvailable />
      }

      {!userIsValidating && phoneNumber && !workers.isValidating && worker &&
        <MainScreen worker={worker}/>
      }

    </Fragment>
  )
}

export default TerminalApp
